<template>
  <div id="pagination">
    <div class="p-left">{{ pageCount }}</div>
    <div class="p-right">
      <a @click="home">
        <i class="sdnm-dbreturn"></i>
      </a>
      <a @click="prev">
        <i class="sdnm-return"></i>
      </a>
      <a
        :class="{ active: (p.page - 1) * pageLength === start }"
        v-for="p in canShow.showArr1"
        @click="go((p.page - 1) * pageLength)"
        :key="p.id"
        ><span v-if="p.show">{{ p.page }}</span></a
      >
      <span v-if="canShow.sym1">...</span>
      <a
        :class="{ active: (p.page - 1) * pageLength === start }"
        v-for="p in canShow.showArr2"
        @click="go((p.page - 1) * pageLength)"
        :key="p.id"
        ><span v-if="p.show">{{ p.page }}</span></a
      >
      <span v-if="canShow.sym2">...</span>
      <a
        :class="{ active: (p.page - 1) * pageLength === start }"
        v-for="p in canShow.showArr3"
        @click="go((p.page - 1) * pageLength)"
        :key="p.id"
        ><span v-if="p.show">{{ p.page }}</span></a
      >
      <a @click="next">
        <i class="sdnm-enter"></i>
      </a>
      <a @click="end">
        <i class="sdnm-dbenter"></i>
      </a>
    </div>
  </div>
</template>

<script>
/**
 * @module components/pagination
 * @desc 表格分页
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-27
 * @copyright 2021
 */
// import { event } from '_/EVENTs';
export default {
  name: "pagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageLength: {
      type: Number,
      default: 10,
    },
    start: {
      type: Number,
      default: 0,
    },
    pageCallback: {},
  },
  computed: {
    /**
     * 翻页描述
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-06
     * @return {String}
     */
    pageCount() {
      if (this.total === 0) {
        return "";
      }
      let start = this.start + 1;
      let end = Math.min((this.start + 1) * this.pageLength, this.total);
      return `第${start}到第${end}条记录，共${this.total}条`;
    },
    /**
     * 计算最大页数／末尾页数
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-06
     * @return {Number}
     */
    maxPage() {
      return Math.ceil(this.total / this.pageLength);
    },
    canShow() {
      let showArr1 = [];
      let sym1 = false;
      let showArr2 = [];
      let sym2 = false;
      let showArr3 = [];
      let activePage = this.start / 10 + 1;
      if (this.maxPage <= 4) {
        for (let p = 0; p < this.maxPage; p++) {
          showArr1.push({
            page: p + 1,
            show: true,
          });
        }
      } else {
        if (this.maxPage + 1 < activePage + 2) {
          // 最后几页
          for (let p = 0; p < this.maxPage; p++) {
            if (p < this.maxPage - 2) {
              if (p === 0) {
                showArr1.push({
                  page: p + 1,
                  show: true,
                });
              }
            } else {
              showArr3.push({
                page: p + 1,
                show: true,
              });
            }
          }
          sym1 = true;
        } else if (activePage - 2 <= 0) {
          // 开头几页
          for (let p = 0; p < this.maxPage; p++) {
            if (p < 3) {
              showArr1.push({
                page: p + 1,
                show: true,
              });
            } else {
              if (p + 1 === this.maxPage) {
                showArr2.push({
                  page: p + 1,
                  show: true,
                });
              }
            }
          }
          sym1 = true;
        } else {
          // 中间几页
          for (let p = 0; p < this.maxPage; p++) {
            if (p === 0) {
              showArr1.push({
                page: p + 1,
                show: true,
              });
            } else if (p + 1 === this.maxPage) {
              showArr3.push({
                page: p + 1,
                show: true,
              });
            } else {
              if (p >= activePage - 2 && p <= activePage + 1) {
                showArr2.push({
                  page: p + 1,
                  show: true,
                });
              }
            }
          }
          sym1 = true;
          sym2 = true;
        }
      }
      return {
        showArr1,
        sym1,
        showArr2,
        sym2,
        showArr3,
      };
      // return new Array(this.maxPage).fill(!true).map((el, index) => {
      // 	let offset = this.start - index;
      // 	if(this.start < 5 && index < this.pageLength){
      // 		return true;
      // 	}
      // 	else if(this.maxPage - this.start < 5 && this.maxPage - this.pageLength < index){
      // 		return true;
      // 	}
      // 	else{
      // 		let l = -5;
      // 		return l <= offset && offset <= 4;
      // 	}
      // });
    },
  },
  methods: {
    /**
     * 回到首页
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-06
     */
    home() {
      this.go();
    },
    /**
     * 回到上一页
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-06
     */
    prev() {
      if (this.start === 0) {
        // do nothing
      } else {
        this.go(this.start - this.pageLength);
      }
    },
    go(index = 0) {
      // event.$emit('sdnm-page', index);
      this.pageCallback(index);
    },
    next() {
      if (this.start + this.pageLength >= this.total) {
        // do nothing
      } else {
        this.go(this.start + this.pageLength);
      }
    },
    end() {
      this.go((this.maxPage - 1) * this.pageLength);
    },
  },
};
</script>

<style lang="less">
// @import (reference) "~less/base/utils.less";
@import url("../less/base/utils.less");

@height: 30px;
#pagination {
  .mgt(24px);
  .pos-r();
  height: 30px;
}
#pagination .p-left {
  .fc(12px, #666666);
  .pos-a(0, 0, 0);
  height: @height;
  line-height: @height;
}
#pagination .p-right {
  .fc(14px, #606a71);
  .pos-atr(1, 0, 0);
  height: @height;
  line-height: @height;
  a {
    .dib();
    .wh(30px);
    line-height: 30px;
    .ta-c();
    .bgc(#f1f3f5);
    .brdr(2px);
    .mg(0, 5px, 5px, 0);
    .cs();
    i {
      color: #606a71;
    }
    &:last-child {
      .mgr(0);
    }
    &.active {
      background-color: inherit;
      color: #257873;
    }
  }
}
</style>
