<template>
  <div class="radio">
    <div
      v-for="(data, index) in radioData"
      class="sdnm-radio"
      @click="change(index)"
    >
      <i
        :class="{
          'sdnm-circular_selected': data.id === radioCheckedId,
          'sdnm-circular_not_selected': data.id !== radioCheckedId,
        }"
      ></i>
      <span>{{ data.name }}</span>
    </div>
  </div>
</template>

<script>
/**
 * @module components/radio
 * @desc 传蔬农贸版 单选
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-10-16
 * @copyright 2021
 */
import { event } from "../modules/EVENTs";
export default {
  name: "radio",
  props: {
    radioData: {
      type: Array,
      default: [],
    },
    radioName: {
      type: String,
      default: "",
    },
    radioCheckedId: {
      type: [String, Number],
      default: 0,
    },
  },
  methods: {
    /**
     * 点击时向父组件传值
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-10-16
     * @param  {int}   index 点击的索引
     */
    change(index) {
      event.$emit("sdnm-radio", index, this.radioName);
    },
  },
};
</script>

<style lang="less">
@import (reference) "../less/common.less";
</style>
