<template>
  <div class="upload-com">
    <div class="cert-img-uploader">
      <file-upload
        ref="upload"
        v-model="file"
        :drop="true"
        :multiple="isList"
        :drop-directory="false"
        :post-action="uploadAction"
        :data="extraData"
        @input-filter="inputFilter"
        @input-file="inputFile"
      >
        <div class="init-image" v-if="!imgUrl">
          {{ title }}
        </div>
      </file-upload>
      <div
        v-if="imgUrl"
        class="hoverImage initImage full"
        :class="{ empty: imgUrl === initIMG.upload }"
        :style="{ 'background-image': 'url(' + imgUrl + ')' }"
      >
        <div
          class="icon-opration"
          v-if="imgUrl !== initIMG.upload && showOpera"
        >
          <div
            class="icon watch sdnm-eye"
            @click.stop.prevent="previrewImage(imgUrl)"
          ></div>
          <div
            class="icon delete sdnm-trash"
            @click.stop.prevent="deletePic"
            v-if="isDelete"
          ></div>
        </div>
      </div>
      <template v-if="!returnObj">
        <div
          v-if="isList"
          class="hoverImage listImg"
          v-for="(item, index) in imgList"
          :style="{ 'background-image': 'url(' + item + ')' }"
        >
          <div class="icon-opration">
            <div
              class="icon watch sdnm-eye"
              @click.stop.prevent="previrewImage(item)"
            ></div>
            <div
              class="icon delete sdnm-trash"
              @click.stop.prevent="deletePic(index)"
              v-if="isDelete"
            ></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="isList"
          class="hoverImage listImg"
          v-for="(item, index) in imgList"
          :style="{ 'background-image': 'url(' + item.path + ')' }"
        >
          <div class="icon-opration">
            <div
              class="icon watch sdnm-eye"
              @click.stop.prevent="previrewImage(item)"
            ></div>
            <div
              class="icon delete sdnm-trash"
              @click.stop.prevent="deletePic(index, item.id)"
              v-if="isDelete"
            ></div>
          </div>
        </div>
      </template>
      <div class="upload-img-title">{{ imgTitle }}</div>
    </div>
    <mimesis
      :show="dbShow"
      :closeCallBack="dbCancel"
      :saveCallBack="dbSave"
      :btnLoadDisabled="dbDisabled"
      :showIcon="true"
      :isDangerous="true"
      saveBtn="确认删除"
    >
      <div>删除后不能恢复，确认删除吗？</div>
    </mimesis>
  </div>
</template>
<script>
/**
 * @module components/imgUpload
 * @desc 封装图片上传预览
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-04-16
 */
import { mapActions } from "vuex";
import { parse } from "../modules/utils";
// import showImage from "@@/showImage";
import FileUpload from "vue-upload-component";
// import mimesis from "@@/mimesis";
import mimesis from "./mimesis";
import showImage from "./showImage";
import APIs from "../modules/APIs";
const actions = mapActions(["setAlert"]);
export default {
  name: "imgUpload",
  components: { FileUpload, showImage, mimesis },
  props: {
    // 上传占位title
    imgTitle: {
      type: String,
    },
    title: {
      type: String,
      default: "点击上传",
    },
    // 默认图片url
    url: {
      type: String,
      default: "/images/load/altpic.png",
    },
    // 是否需要删除
    isDelete: {
      type: Boolean,
      default: true,
    },
    // 是否需要显示查看、删除
    showOpera: {
      type: Boolean,
      default: true,
    },
    // 上传接口
    uploadAction: {
      type: String,
      default: APIs.CERT,
    },
    // 上传接口数据
    extraData: {
      type: Object,
      default: {},
    },
    // name用于多个图片上传组件时回调取值
    name: {
      type: String,
      default: "file",
    },
    // 是否多图上传并显示
    isList: {
      type: Boolean,
      default: false,
    },
    // 设置上传图片大小
    size: {
      type: Number,
      default: 3,
    },
    // 初始传入的多图list
    urlList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    // 删除图片是否调用接口
    isDeleteUrl: {
      type: Boolean,
      default: false,
    },
    // 删除图片是否调用接口
    uploadNum: {
      type: Number,
      default: 0,
    },
    // 删除图片直接返回id
    returnObj: {
      type: Boolean,
      default: false,
    },
    // 删除图片直接返回id
    addhttp: {
      type: Boolean,
      default: false,
    },
    // 图片压缩质量： 区间0-1，传1默认不压缩，值越低压缩度越高
    quantity: {
      type: [Number],
      default: 1,
    },
  },
  data() {
    return {
      imgUrl: "", // 图片url
      imgList: [], // 图片列表
      file: [],
      initIMG: {
        upload: "/images/load/altpic.png",
        show: "/images/load/imgload.png",
      },
      type: 1, // 1->新增，2->删除
      dbShow: false,
      dbDisabled: false,
      tempIndex: "",
      tempId: "",
      compressing: false, // 是否正在压缩
    };
  },
  watch: {
    url(nv, ov) {
      this.imgUrl = nv;
    },
    urlList(nv, ov) {
      this.imgList = parse(nv);
    },
  },
  methods: {
    ...actions,
    previrewImage(url) {
      this.$emit("upload-preview", url);
    },
    deletePic(index, id) {
      this.tempIndex = index;
      this.tempId = id;
      this.dbShow = true;
    },
    dbSave() {
      let index = this.tempIndex;
      let id = this.tempId;
      let data = "";
      if (!this.isDeleteUrl) {
        if (this.returnObj) {
          // 根据Id删除
          this.$emit("delete-callback", index, id, this.name);
        } else {
          if (this.isList) {
            this.imgList.splice(index, 1);
          }
          data = this.isList ? this.imgList : this.imgUrl;
          this.$emit("upload-callback", data, 2, this.name);
          if (!this.isList) {
            this.imgUrl = this.initIMG.upload;
          }
        }
      } else {
        this.imgList.splice(index, 1);
        this.$emit("delete-callback", index, 2, this.name);
      }
      this.dbCancel();
    },
    dbCancel() {
      this.tempIndex = "";
      this.dbShow = false;
    },
    /**
     * 做文件类型筛选
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-04-17
     * @param  {file}   newFile 新选取的文件
     * @param  {file}   oldFile
     * @param  {function}   prevent 阻止默认上传
     */
    inputFilter: function(newFile, oldFile, prevent) {
      if (this.uploading) {
        return;
      }
      if (newFile) {
        // 过滤不是图片后缀的文件
        if (!/\.(png|jpeg|jpg)$/i.test(newFile.name)) {
          this.setAlert({
            msg: "上传附件格式不正确，文件必须.png/.jpeg/.jpg格式",
            type: false,
          });
          return prevent();
        }
        if (newFile.size > 1024 * 1024 * this.size) {
          this.setAlert({
            msg: `上传文件不得超过${this.size.toString()}M`,
            type: false,
          });
          return prevent();
        }
        console.log("压缩前：" + newFile.size);
        // quantity等于1表示不压缩；大于1M的图片进行压缩，其他的正常上传；压缩一次后不小于1M就不再压缩
        if (
          this.quantity < 1 &&
          newFile.file &&
          newFile.size > 1024 &&
          !this.compressing
        ) {
          // newFile.error = 'compressing';
          this.compressing = true;
          // 压缩开始
          this.compress(newFile.file)
            .then((file) => {
              // 更新file
              console.log("压缩后：" + file.size);
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.uploader.update(newFile, {
                error: err.message || "compress",
              });
            });
        }
      }
    },
    /**
     * 文件上传
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-04-17
     * @param  {file}   newFile 上传文件
     * @param  {file}   oldFile
     */
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // 上传
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile) {
        // 上传错误
        if (newFile.error !== "" && newFile.error !== oldFile.error) {
          this.setAlert({ msg: "上传发生错误", type: false });
          this.compressing = false;
        }

        // 上传成功
        if (newFile.success !== oldFile.success) {
          if (newFile.response.code === "0") {
            let url = "";
            if (this.returnObj) {
              if (this.addhttp) {
                url = newFile.response.data;
                url.path = "//" + url.path;
              } else {
                url = newFile.response.data;
              }
            } else {
              if (this.addhttp) {
                url = "//" + newFile.response.data.path;
              } else {
                url = newFile.response.data.path;
              }
            }
            if (this.isList) {
              if (this.uploadNum === 0) {
                this.imgList.push(url);
              } else {
                if (this.imgList.length === parseInt(this.uploadNum)) {
                  this.setAlert({
                    msg: `限制上传${this.uploadNum}张`,
                    type: false,
                  });
                } else if (this.imgList.length < parseInt(this.uploadNum)) {
                  this.imgList.push(url);
                }
              }
            } else {
              this.imgUrl = url;
            }
            let data = this.isList ? this.imgList : this.imgUrl;
            this.$emit("upload-callback", data, 1, this.name);
          } else {
            this.setAlert({ msg: newFile.response.msg, type: false });
          }
          this.compressing = false;
        }
      }
    },
    /**
     * 压缩
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-11-19
     * @version  0.1.0
     */
    compress(imgFile) {
      let _this = this;
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            // 铺底色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height);

            // 进行压缩
            let ndata = canvas.toDataURL("image/jpeg", _this.quantity);
            resolve(_this.dataURLtoFile(ndata, imgFile.name));
          };
        };
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(imgFile);
      });
    },
    /**
     * base64 转 File
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-11-19
     * @version  0.1.0
     */
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  mounted() {
    this.imgUrl = this.url;
    this.imgList = parse(this.urlList);
  },
};
</script>
<style lang="less">
@import url("../less/common.less");
.upload-com {
  .all-content {
    text-align: left;
  }
}
.cert-img-uploader {
  .pos-r();
  .dib();
  .ta-c();
  label {
    width: 100px;
    min-height: 90px;
    background-color: #f5f5f5;
    .brd(@borderColor: #e5e5e5);
    .brdr(4px);
    z-index: 5;
    float: left;
    .mg(0, 20px, 5px, 20px);
  }
  .initImage {
    position: absolute;
    &.full {
      // top: 0;
      bottom: -14px;
      // left: 20px;
      right: 0;
      height: 92px;
      min-height: 92px;
      z-index: 10;
    }
    &.empty {
      width: 50px;
      height: 50px;
      min-height: 50px;
      left: 47px;
      top: 10px;
      z-index: 5;
    }
  }
  .listImg {
    height: 92px;
    float: left;
    .mg(0, 20px, 5px, 20px);
  }
  .hoverImage {
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    &:hover {
      .icon-opration {
        .fc(14px, #fff);
        background: rgba(0, 0, 0, 0.5);
        .icon {
          .cs(pointer);
          &:hover {
            .fgc(#4287fd);
          }
        }
      }
    }
    .icon-opration {
      height: 100%;
      padding-top: 38px;
      font-size: 0;
      .icon {
        display: inline-block;
        &.delete {
          margin-left: 15px;
        }
      }
    }
  }
  .init-image {
    .cs(pointer);
    .fc(12px, #4a87fd);
    height: 90px;
    padding-top: 62px;
  }
}
.upload-img-title {
  .mgb(10px);
  .fc(14px, #525e6e);
}
</style>
