<template>
  <div class="wrapper" id="product">
    <ctheader></ctheader>
    <div ref="product" class="content">
      <div class="table-head">
        <input
          type="text"
          class="search"
          placeholder="搜索商品名称"
          @keypress.enter="list()"
          v-model="search.name"
        />
        <i class="sdnm-icon sdnm-search" @click="list()"></i>
        <searchtree
          class="mgl-8"
          :searchOptions="categorys"
          searchPlaceholder="搜索商品分类"
          inputPlaceholder="全部分类"
          :width="150"
          name="prosearch"
          @sdnm-select-tree="getCid"
        >
        </searchtree>
        <i class="sdnm-icon sdnm-search" @click="list()"></i>
        <select
          class="w150 mgl-8 ver-ali-top"
          v-model="search.status"
          @change="list()"
        >
          <option value="">全部状态</option>
          <option :value="row.id" v-for="row in statusList">{{
            row.name
          }}</option>
        </select>
        <div class="right">
          <div class="importData">
            <span>批量导入商品</span>
            <div class="sub-item">
              <button
                class="btn secondary"
                @click="downloadModel"
                :disabled="modelDisadled"
              >
                下载导入模板
              </button>
              <a :href="modelUrl" v-click="downloadMod"></a>
              <file-upload
                v-model="files"
                :post-action="postAction"
                :data="excelData"
                :size="size"
                :timeout="timeout"
                @input-file="inputFile"
                ref="upload"
              >
                <div>批量导入商品</div>
              </file-upload>
            </div>
          </div>
          <load-button
            class="btn"
            :class="{ secondary: !disabledExport, primary: disabledExport }"
            btnName="导出商品"
            :disabled="disabledExport"
            :callback="loadCallback"
          >
          </load-button>
          <button class="btn primary ver-ali-top" @click="newGoods">
            新建商品
          </button>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr class="have-img">
              <th class="pw20"></th>
              <th class="pw20">商品名称</th>
              <th class="pw30">商品分类</th>
              <th class="pw15">商品状态</th>
              <th class="pw15">操作</th>
            </tr>
          </thead>
          <tbody :style="{ height: tbHeight + 'px' }">
            <template v-for="row in tbody">
              <tr class="have-img">
                <td class="pw20 ellipsis" :title="row.op_type">
                  <img
                    :src="row.img_url"
                    alt=""
                    @click="uploadPreview(row.img_url)"
                  />
                </td>
                <td class="pw20 ellipsis" :title="row.admin_name">
                  {{ row.name }}
                </td>
                <td class="pw30 ellipsis" :title="row.ip">
                  {{ row.cate_name }}
                </td>
                <td class="pw15 ellipsis" :title="row.created_at">
                  {{ row.status === 2 ? "禁用" : "启用" }}
                </td>
                <td class="pw15 ellipsis operation" :title="row.created_at">
                  <a href="javascript:;" @click="changeStatus(row)">{{
                    row.status === 1 ? "禁用" : "启用"
                  }}</a>
                  <a href="javascript:;" @click="editPro(row)">编辑</a>
                  <a href="javascript:;" @click="isSure(1, row.id)">删除</a>
                </td>
              </tr>
            </template>
            <tr v-if="tbody.length === 0">
              <td class="empty" colspan="7">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="search.start"
        :total="total"
        :page-length="search.length"
        :pageCallback="list"
      ></pagination>
    </div>
    <layers
      :show="layerNew.show"
      :title="layerNew.id === '' ? '新建商品' : '编辑商品'"
      :width="400"
      :btnLoadDisabled="layerNew.disabled"
      :saveCallBack="saveCallBack"
      :closeCallBack="closeCallBack"
    >
      <div class="form">
        <div class="field is-12">
          <label class="w110 tart">商品名称：</label>
          <input
            type="text"
            v-model="layerNew.name"
            class="w200"
            placeholder=""
            maxlength="11"
          />
        </div>
        <div class="field">
          <label class="w110 tart">商品分类：</label>
          <searchtree
            :searchOptions="categorys"
            searchPlaceholder="搜索商品分类"
            inputPlaceholder="全部分类"
            :width="200"
            :choosedInfo="choosedInfo"
            name="layerPro"
            @sdnm-select-tree="getCid"
          >
          </searchtree>
        </div>
        <div class="field">
          <label class="w110 tart">商品启用：</label>
          <radio
            :radioData="statusList"
            :radioCheckedId="layerNew.status"
          ></radio>
        </div>
        <div class="field img-center">
          <img-upload
            :upload-action="uploadAction"
            :extra-data="uploadData"
            :url="uploadUrl"
            :isDelete="true"
            imgTitle=""
            title="上传商品图片"
            name="file1"
            :returnObj="true"
            @delete-callback="deletePic"
            @upload-preview="uploadPreview"
            @upload-callback="uploadImg"
          >
          </img-upload>
          <div class="upload-tip" v-if="!uploadUrl">
            上传图片格式必须为png、jpeg、jpg，不大于3M
          </div>
        </div>
      </div>
    </layers>
    <showImage
      :url="perviewUrl"
      :showBigImage="perviewShow"
      :closeCallBack="perviewCancel"
    >
    </showImage>
    <mimesis
      :show="dbShow"
      :title="dbTitle"
      :closeCallBack="dbCancel"
      :saveCallBack="dbEnsure"
      :btnLoadDisabled="dbDisabled"
      :showIcon="true"
      :isDangerous="dbColor"
      :saveBtn="dbSave"
    >
      <div>{{ dbContent }}</div>
    </mimesis>
  </div>
</template>

<script>
// import loadBtn from '@@/loadBtn';
// import ctheader from '@@/ctheader';
import fileUpload from "vue-upload-component";
// import pagination from '@@/pagination';
// import imgUpload from '@@/imgUpload';
// import showImage from '@@/showImage';
// import radio from '@@/radio';
// import layers from '@@/layers';
// import searchtree from '@@/searchtree';
// import mimesis from '@@/mimesis';
import loadBtn from "../../components/loadBtn";
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import imgUpload from "../../components/imgUpload";
import showImage from "../../components/showImage";
import radio from "../../components/radio";
import layers from "../../components/layers";
import searchtree from "../../components/searchtree";
import mimesis from "../../components/mimesis";
import { event } from "../../modules/EVENTs";
import APIs from "../../modules/APIs";
import { apiPost, parse } from "../../modules/utils";
import { mapActions } from "vuex";
const actions = mapActions(["setAlert"]);

const LAYER_NEW = {
  show: false,
  disabled: false,
  name: "",
  cate_id: "",
  status: 1,
  id: "",
  img_url: "/images/load/altpic.png",
};

export default {
  name: "product",
  data() {
    return {
      search: {
        name: "",
        cate_id: "",
        status: "",
        start: 0,
        length: 10,
      },
      categorys: [],
      statusList: [
        { id: 1, name: "启用" },
        { id: 2, name: "禁用" },
      ],
      modelUrl: "", // 模板文件导出地址
      downloadMod: false,
      modelDisadled: false,
      files: [], // 导入分类文件的信息
      postAction: APIs.PRODUCT, // 导入分类的接口地址
      excelData: { action: "import.product" }, // 导入分类的接口请求数据
      size: 1024 * 10240, // 设置上传的excel文件大小
      timeout: 6 * 1000, // 设置上传文件超时时间
      // 导出
      excelUrl: "", // 文件导出地址
      download: !true, // 报表数据是否开始下载,
      disabledExport: false, // 加载中按钮样式控制
      // 表格
      tbHeight: "",
      tbody: [],
      total: 0,
      // 弹窗
      layerNew: parse(LAYER_NEW),
      choosedInfo: {},
      // 上传图片
      initIMG: {
        upload: "/images/load/altpic.png",
        show: "/images/load/imgload.png",
      },
      uploadAction: APIs.PRODUCT,
      uploadData: {
        action: "product.img.upload",
      },
      // uploadUrl: "/images/load/altpic.png",
      uploadUrl: "",
      perviewUrl: "", // perview
      perviewShow: false,
      // 二次确认
      dbShow: false,
      dbDisabled: false,
      dbSave: "",
      dbTitle: "",
      dbContent: "",
      dbColor: "",
      config: {}, // temp data
    };
  },
  components: {
    ctheader,
    loadBtn,
    fileUpload,
    pagination,
    imgUpload,
    radio,
    layers,
    showImage,
    searchtree,
    mimesis,
  },
  directives: {
    click: {
      update(el, { value, oldValue }) {
        if (value !== oldValue && value) {
          el.click();
        }
      },
    },
  },
  methods: {
    ...actions,
    /**
     * 获取分类树
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    getCategorys() {
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "list.category.tree",
        },
        success(res) {
          if (res.code === "0") {
            let categorys = res.data.map((el) => {
              el.show = true;
              el.children.map((el2) => {
                el2.children.map((el3) => {
                  el3.children.map((el4) => {
                    el4.show = true;
                    return el4;
                  });
                  el3.show = true;
                  return el3;
                });
                el2.show = true;
                return el2;
              });
              return el;
            });
            categorys = [{ id: "", name: "全部分类" }].concat(categorys);
            $this.categorys = JSON.parse(JSON.stringify(categorys));
          } else {
            $this.categorys = [];
          }
        },
        error(err) {
          $this.categorys = [];
          console.log(err);
        },
      });
    },
    /**
     * 状态下拉组件返回值
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-07-11
     */
    getCid(row, name) {
      if (name === "layerPro") {
        this.layerNew.cate_id = row.id;
        return;
      }
      this.search.cate_id = row.id;
      this.list();
    },
    /**
     * 列表
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    list(index) {
      if (index || index === 0) {
        this.search.start = index;
      } else {
        this.search.start = 0;
      }
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "list.product",
          name: $this.search.name,
          cate_id: $this.search.cate_id,
          status: $this.search.status,
          start: $this.search.start,
          length: $this.search.length,
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.tbody = res.data;
            $this.total = res.total;
            $this.tResize();
          } else {
            $this.setAlert({ msg: res.msg, type: false });
            $this.total = 0;
          }
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
          $this.total = 0;
        },
      });
    },
    /**
     * 表格高度重置
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    tResize() {
      this.tbHeight = this.tableResize(
        this.$refs.product.clientHeight,
        200,
        this.tbody.length
      );
    },
    tableResize(clHeight, rmHeight, showTrNum) {
      if (showTrNum === 0) {
        showTrNum = 1;
      }
      let ctHeight = clHeight - rmHeight;
      let allTrHeight = showTrNum * 101;
      let tbHeight = ctHeight;
      if (allTrHeight < ctHeight) {
        tbHeight = allTrHeight;
      }
      return tbHeight;
    },
    /**
     * 删除
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    deletePro() {
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "delete.product",
          id: $this.config.id,
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.list();
            $this.dbCancel();
            $this.setAlert({ msg: res.msg, type: true });
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
        },
      });
    },
    /**
     * 加载按钮回调方法
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-03
     * @version  0.1.0
     */
    loadCallback() {
      this.disabledExport = true;
      this.outExcel();
    },
    /**
     * 导出列表
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-03
     * @version  0.1.0
     */
    outExcel() {
      let str = "";
      for (let i in this.search) {
        if (this.search[i]) {
          str += `&service_params[${i}]=${this.search[i]}`;
        }
      }
      this.excelUrl =
        "/api/export?service_name=ProductService&service_method=export_product_data&service_namespace=Product" +
        str;
      window.open(this.excelUrl);
      this.disabledExport = false;
    },
    /**
     * 添加商品分类导入
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    inputFile(newFile, oldFile) {
      console.log(newFile);
      if (newFile.type == "image/png" || newFile.type == "image/jpeg") {
        newFile.data.action = "product.img.upload";
      }
      if (newFile && !oldFile) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile) {
        // 开始上传
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);
        }
        // 上传进度
        if (newFile.progress !== oldFile.progress) {
        }
        // 上传错误
        if (newFile.error !== oldFile.error) {
          this.setAlert({ msg: "导入商品数据失败", type: false });
        }
        // 上传成功
        if (newFile.success !== oldFile.success) {
          let res = newFile.response;
          if (typeof res === "string") {
            // 为了解决莫名出现的json返回问题，该问题后端还没找到原因，暂时先这样处理
            res = JSON.parse(res);
          }
          console.log(res);
          if (res.code === "0") {
            let info = "商品";
            this.setAlert({
              // msg: `导入${res.data.success_num}条${info}成功`,
              msg: res.msg,
              type: true,
            });
            let url = "http://" + res.data.domain + "/" + res.data.path;
            this.uploadUrl = url;
            console.log(url);
            this.layerNew.img_url = this.uploadUrl;
            // this.list();
          } else if (res.code === "-1") {
            let d = res.data;
            this.isSure(2, d.success_num, d.fail_num);
            this.config.tempUrl = d.url;
            this.list();
          } else {
            this.setAlert({ msg: res.msg, type: false });
          }
        }
      }
    },
    downloadModel(type) {
      this.modelDisadled = true;
      this.outModel(1);
    },
    /**
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param {Number} type 1->导入模板，2->导入失败下载
     */
    outModel(type) {
      let url =
        type === 1
          ? "/api/export?service_name=ProductService&service_method=export_product_template&service_namespace=Product"
          : this.config.tempUrl;
      this.modelUrl = url;
      window.open(this.modelUrl);
      if (type === 1) {
        this.modelDisadled = false;
      } else {
        this.dbDisabled = false;
        this.dbCancel();
      }
    },
    /**
     * 删除商品 二次确认操作dbEnsure -> 确认,dbCancel -> 取消
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param  {Number}   二次确认类型 1->删除，2->下载弹框
     */
    isSure(type, index, id, indexStr) {
      this.config.dbType = type;
      if (type === 1) {
        this.dbContent = "确认删除吗？";
        this.dbSave = "确认删除";
        this.config.id = index;
      } else {
        let msg = "商品";
        this.dbTitle = `成功导入${index}条${msg}信息`;
        this.dbContent = `尚有${id}条${msg}信息有误，请下载有误的${msg}信息！`;
        this.dbSave = "下载有误的Excel文档";
        this.dbColor = false;
      }
      this.dbShow = true;
    },
    dbCancel() {
      this.config = {};
      this.dbShow = false;
    },
    dbEnsure() {
      if (this.config.dbType === 1) {
        this.deletePro();
        return;
      }
      this.dbDisabled = true;
      this.outModel(2);
    },
    newGoods() {
      this.layerNew.show = true;
    },
    editPro(row) {
      this.layerNew.id = row.id;
      this.layerNew.cate_id = row.cate_id;
      this.choosedInfo = {
        id: row.cate_id,
        name: row.cate_name,
      };
      this.layerNew.name = row.name;
      this.layerNew.status = row.status;
      this.layerNew.img_url = row.img_url;
      this.uploadUrl = row.img_url;
      this.layerNew.show = true;
    },
    /**
     * 上传图片
     */
    perviewCancel() {
      this.perviewShow = false;
    },
    deletePic() {
      this.perviewUrl = "";
      this.perviewShow = false;
      // this.uploadUrl = this.initIMG.upload;
      this.uploadUrl = "";
      this.layerNew.img_url = this.initIMG.upload;
    },
    uploadPreview(url) {
      this.perviewUrl = url;
      this.perviewShow = true;
    },
    uploadImg(path, type, name) {
      console.log(path);
      let url = "https://" + path.domain + "/" + path.path;
      this.uploadUrl = type === 1 ? url : this.initIMG.upload;
      this.layerNew.img_url = this.uploadUrl;
    },
    saveCallBack() {
      if (this.layerNew.name === "") {
        this.setAlert({ msg: "请填写商品名称", type: false });
        return;
      }
      if (this.layerNew.cate_id === "") {
        this.setAlert({ msg: "请选择商品分类", type: false });
        return;
      }
      if (this.layerNew.img_url === this.initIMG.upload) {
        this.setAlert({ msg: "请上传商品图片", type: false });
        return;
      }
      let $this = this;
      let data = {
        action: "add.product",
        name: $this.layerNew.name,
        cate_id: $this.layerNew.cate_id,
        status: $this.layerNew.status,
        img_url: $this.layerNew.img_url,
      };
      if (this.layerNew.id !== "") {
        // 编辑
        data.action = "edit.product";
        data.id = this.layerNew.id;
      }
      apiPost({
        url: APIs.PRODUCT,
        data: data,
        success(res) {
          if (res.code + "" === "0") {
            $this.list($this.search.start);
            // console.log();
            $this.closeCallBack();
            $this.setAlert({ msg: res.msg, type: true });
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
        },
      });
    },
    closeCallBack() {
      this.layerNew = parse(LAYER_NEW);
      this.choosedInfo = {};
      this.uploadUrl = "";
    },
    changeStatus(row) {
      let $this = this;
      let status = row.status === 1 ? 2 : 1;
      let data = {
        action: "edit.product",
        id: row.id,
        name: row.name,
        cate_id: row.cate_id,
        status: status,
        img_url: row.img_url,
      };
      apiPost({
        url: APIs.PRODUCT,
        data: data,
        success(res) {
          if (res.code + "" === "0") {
            row.status = status;
            $this.setAlert({ msg: "状态更改成功", type: true });
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
        },
      });
    },
    checkRadio(index) {
      this.layerNew.status = index + 1;
    },
  },
  mounted() {
    this.getCategorys();
    this.list();
    event.$on("sdnm-radio", this.checkRadio);
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#product {
  .mgl-8 {
    .mgl(8px);
  }
  .ver-ali-top {
    vertical-align: top;
  }
  .importData {
    .pos-r();
    .dib();
    height: 30px;
    .mgr(8px);
    .fc(12px, #525e6e);
    .va-t();
    .brd(1px, solid, #a0b9db);
    .pdl(14px);
    .pdr(14px);
    .sub-item {
      height: 60px;
      > button {
        border: none;
        .mgr(0);
      }
      .dn();
    }
    &:hover {
      .fgc(#257873);
      .brd(1px, solid, #257873);
      .sub-item {
        text-align: center;
        position: absolute;
        left: -1px;
        top: 28px;
        width: 102px;
        z-index: 1;
        .bgc(#fff);
        .brd(1px, solid, #a0b9db);
        .db();
        > label > div {
          width: 102px;
          .fc(12px, #525e6e);
          .cs();
          &:hover {
            .fgc(#257873);
          }
        }
      }
    }
  }
  table tr.have-img td {
    .hlh(100px, 100px);
    img {
      max-width: 100px;
      max-height: 90px;
    }
    &:first-child {
      .ta-c();
      img {
        vertical-align: middle;
      }
    }
  }
  .l-content {
    .pdl(24px);
    .pdr(24px);
    .pdt(24px);
  }
  .layer {
    .upload-tip {
      .fc(12px, #525e6e);
    }
    .img-center {
      width: 100%;
      text-align: center;
    }
    .search-select-options {
      left: -80px;
      z-index: 20;
    }
  }
  .initImage {
    position: absolute;
    &.full {
      // top: 0;
      // bottom: -14px;
      // left: 20px;
      top: 0;
      left: -55px;
      right: 0;
      height: 92px;
      min-height: 92px;
      z-index: 10;
    }
  }
}
</style>
